/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { ErrorPage } from "./pages/ErrorPage";
// import UpgradePage from "./components/UpgradePage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  const params = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(params.entries());
  return (
    <Switch>
      <Route path="/error" component={ErrorPage} />
      <Route path="/logout" component={Logout} />
      <Route
        path="/auth/login/:status?/:auth_token?/:refresh_token?/:user?"
        component={AuthPage}
      />
      {!isAuthorized ? (
        <>
          <Redirect
            to={{
              pathname: "/auth/login",
              search: `?redirectTo=${encodeURIComponent(
                window.location.pathname + window.location.search
              )}`
            }}
          />
        </>
      ) : queries.redirectTo ? (
        <Redirect from="/auth" to={`${queries.redirectTo}?redirect=1`} />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect
          to={{
            pathname: "/auth/login",
            search: `?redirectTo=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          }}
        />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
